<template>
  <print-table v-if="form.id" :form="form" :historyRecord="historyRecord"
               title="入职流转单">
    <template v-slot:form>
        <div class="info_item">
          <div>姓名</div>
          <div>{{form.staffName}}</div>
        </div>
        <div class="info_item">
          <div>手机号</div>
          <div>{{form.mobile}}</div>
        </div>
        <div class="info_item">
          <div>部门</div>
          <div>{{form.sysDeptName && form.sysDeptName.deptName}}</div>
        </div>
        <div class="info_item">
          <div>岗位</div>
          <div>{{
              form.sysPositionNameList &&
              form.sysPositionNameList
              .map((e) => e.positionName)
              .join(",")}}</div>
        </div>
        <div class="info_item">
          <div>入职日期</div>
          <div>{{form.entryTime || "无" }}</div>
        </div>
        <div class="info_item">
          <div>劳动合同主体</div>
          <div>{{form.contractsPartA || "无" }}</div>
        </div>
        <div class="info_item">
          <div>是否为经理人</div>
          <div>{{form.isManager ? "是" : "否" }}</div>
        </div>
        <div class="info_item">
          <div>是否为党员</div>
          <div>{{form.joinPartyTime ? "是" : "否" }}</div>
        </div>
    </template>
  </print-table>
</template>

<script>
import PrintTable from "@/components/print-table";

export default {
  name: "entry-flow-print",
  components: {PrintTable},
  data(){
    return {
      id:0,
      form:{},
      historyRecord:[],
      title:''
    }
  },
  created(){
    this.id=this.$route.query.id;
    this.getDetail();
  },
  methods:{
    getDetail() {
      this.$api.personnel.approvalPersonOperationGet(this.id).then((res) => {
        this.form = res.data;
        if(res.data.hasHistory){
          this.getHistoryActivity(this.id);
        }
      })
    },
    getHistoryActivity(id){
      this.$api.personnel
          .approvalPersonOperationGetHistory({ id })
          .then(({ data }) => {
            this.historyRecord = data.list;
          });
    }
  }

}
</script>

<style scoped lang="scss">


</style>